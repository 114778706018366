import { ExchangeName } from "@b-cube/database/types";

import { IconType } from "../models/icon-type.enum";

export const PATH_TO_ICONS = './assets/img/icons';
export const DEFAULT_B_CUBE_BOT_ICON = 'b-cube-logo-color';
export const BOT_OVERVIEW_NAME = 'Agents overview';
export const MOONMAN_NAME = 'Moonman';
export const OVERHEAD_GAINS_NAME = 'Overhead Gains';
export const SKYITAL = 'Skyital';
export const GURUBOT_NAME = 'GuruBot';
export const SHIRE_ANALYTICS_NAME = 'Shire Analytics';

export const ICONS_FOLDER: any = {
	[IconType.DEFAULT]: `${PATH_TO_ICONS}`,
	[IconType.BOT]: `${PATH_TO_ICONS}/bots`,
	[IconType.EXCHANGE]: `${PATH_TO_ICONS}/exchanges`,
	[IconType.WALLET_PROVIDERS]: `${PATH_TO_ICONS}/providers`,
	[IconType.CRYPTOCURRENCY]: `./assets/img/tokens`,
}

export const EXCHANGES_ICONS_NAMES: any = {
	'overview': 'pie',
	[ExchangeName.BINANCE_SPOT]: 'binance_spot',
	[ExchangeName.BINANCE_FUTURES]: 'binance_futures',
	[ExchangeName.BITGET_FUTURES]: 'bitget_futures',
	[ExchangeName.BITMEX_FUTURES]: 'bitmex_futures',
	[ExchangeName.BYBIT_FUTURES]: 'bybit_futures',
	[ExchangeName.BYBIT_SPOT]: 'bybit_spot',
	[ExchangeName.HUOBI_SPOT]: 'huobi_spot',
	[ExchangeName.HUOBI_FUTURES]: 'huobi_futures',
	[ExchangeName.KRAKEN_SPOT]: 'kraken_spot',
	[ExchangeName.KUCOIN_SPOT]: 'kucoin_spot',
	[ExchangeName.KUCOIN_FUTURES]: 'kucoin_futures',
	[ExchangeName.COINBASE]: 'coinbase'
}

export const THIRD_PARTY_NAME: string[] = [
	MOONMAN_NAME, OVERHEAD_GAINS_NAME, SKYITAL, GURUBOT_NAME
];

export const BOT_ICONS_NAMES: any = {
	[BOT_OVERVIEW_NAME]: 'bot',
	[MOONMAN_NAME]: 'logo-moonman',
	[OVERHEAD_GAINS_NAME]: 'logo-overheadgains',
	[SKYITAL]: 'logo_skyital',
	[GURUBOT_NAME]: DEFAULT_B_CUBE_BOT_ICON,
	[SHIRE_ANALYTICS_NAME]: 'logo-shire-analytics'
}
