export enum ExchangeName {
  BINANCE_SPOT = 'binance_spot',
  BINANCE_FUTURES = 'binance_futures',
	MOCK_BINANCE_FUTURES = 'mock_binance_futures',

	BITGET_SPOT = 'bitget_spot',
  BITGET_FUTURES = 'bitget_futures',

	BITMEX_SPOT = 'bitmex_spot',
  BITMEX_FUTURES = 'bitmex_futures',

  HUOBI_SPOT = 'huobi_spot',
  HUOBI_FUTURES = 'huobi_futures',

  BYBIT_SPOT = 'bybit_spot',
  BYBIT_FUTURES = 'bybit_futures',

  KRAKEN_SPOT = 'kraken_spot',

	COINBASE = 'coinbase',

	KUCOIN_SPOT = 'kucoin_spot',
	KUCOIN_FUTURES = 'kucoin_futures'
}
